@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* .error-popup {
  @apply bg-red-500 text-white p-3 rounded-lg mt-2 shadow-md max-w-[400px] mx-auto text-left text-xs font-serif;
} */
.error-popup {
  background-color: #ef4444; 
  color: #ffffff; 
  padding: 0.5rem; 
  border-radius: 0.5rem; 
  margin-top: 0.5rem; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  max-width: 400px; 
  margin-left: auto; 
  margin-right: auto; 
  text-align: left; 
  font-size: 0.75rem; 
  font-family: serif; 
}

.custom-scrollbar::-webkit-scrollbar {
  width: 12px; 
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #ddd5d5;
  border-radius: 10px;
  height: 50%; 
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #c0b5b5;
}

